import { randomNumber, validUploadFile } 	from '../../constants/config';

export default {
    state: () => ({
        cart						: { products: [], details: null },
        cart_save_later				: { products: [], details: null },
        coupon                      : null,
        product                     : null,
        variants                    : [],
        checkout_data               : null,
        checkout_products           : { products: [], details: null },
        collection                  : null,
        list_products               : null,
        filters_collection          : [],
        searchProducts              : [],
        collectionHome              : [],
        priceCatalog                : [],
        listAddBestSellers			: [],
        notification_products		: [],
        url_download_price_catalog  : "",
        url_download_cart_file		: "",
        url_download_checkout_file	: "",
        products_recently_viewed    : []
    }),
    mutations: {
        setCart(state, payload) {
            state.cart = payload
        },
        setCartSaveLater(state, payload) {
            state.cart_save_later = payload
        },
        setCoupon(state, payload) {
            state.coupon = payload
        },
        setCollection(state, payload) {
            state.collection = payload
        },
        setProduct(state, payload) {
            state.product = payload
        },
        setVariants(state, payload) {
            state.variants = payload
        },
        setProducts(state, payload) {
            state.list_products = payload
        },
        setCheckoutData(state, payload) {
            state.checkout_data = payload
        },
        setCheckoutLineItems(state, payload) {
            state.checkout_products = payload
        },
        setFiltersCollection(state, payload) {
            state.filters_collection = payload
        },
        setSearchProduct(state, payload) {
            state.searchProducts = payload
        },
        setListAddBestSellers(state, payload) {
            state.listAddBestSellers = payload; 
        },
        setCollectionHome(state, payload) {
            state.collectionHome = payload
        },
        setPriceCatalog(state, payload) {
            state.priceCatalog = payload
        },
        setNotificationProductCart(state, payload) {
            state.notification_products = payload; 
        },
        setDownloadPriceCatalog(state, payload) {
            state.url_download_price_catalog = payload
        },
        setDownloadCartFile(state, payload) { 
            state.url_download_cart_file = payload; 
        },
        setProductsRecentlyViewed(state, payload) {
            state.products_recently_viewed = payload;
        }
    },
    getters: {
        getterCart						: state => state.cart,
        getterCartSaveLater				: state => state.cart_save_later,
        getterCoupon                    : state => state.coupon,
        getterCollection                : state => state.collection,
        getterProduct                   : state => state.product,
        getterProducts                  : state => state.list_products,
        getterVariants                  : state => state.variants,
        getterCheckoutData              : state => state.checkout_data,
        getterCheckoutLineItems          : state => state.checkout_products,
        getterFiltersCollection         : state => state.filters_collection,
        getterSearchProduct             : state => state.searchProducts,
        getterCollectionHome            : state => state.collectionHome,
        getterListAddBestSellers		: state => state.listAddBestSellers,
        getterPriceCatalog              : state => state.priceCatalog,
        getterNotificationProductCart	: state => state.notification_products,
        getterDownloadPriceCatalog      : state => state.url_download_price_catalog,
        getterDownloadCartFile			: state => state.url_download_cart_file,
        getterProductsRecentlyViewed    : state => state.products_recently_viewed
    },
    actions: {
        // =================================================================
        //  CART ACTIONS
        // =================================================================
        async actionCart({commit}, payload){
            
            await this.$hefesto.$put(`/carts/${ payload.action }`, { products: payload.products } ).then( (axios_response) => { 
                
                commit('setCoupon', axios_response.body.coupon);
                commit('setCart', axios_response.body.cart);
                commit('setCartSaveLater', axios_response.body.save_later);
                if( payload.action === 'add' ){
                    commit('setNotificationProductCart', axios_response.body.update_product_cart);
                }
            }).catch( (axios_error) => {
                
                commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
            });
        },
        async getCart({commit}, payload){
            
            await this.$hefesto.$post(`/carts/products`).then( (axios_response) => { 
                
                commit('setCoupon', axios_response.body.coupon );
                commit('setCart', axios_response.body.cart );
                commit('setCartSaveLater', axios_response.body.save_later );
            }).catch( (axios_error) => {
                
                commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
            });
        },
        async getCheckoutOrder({commit}, payload){

            await this.$hefesto.$post(`/carts/checkout-order`, payload).then( (axios_response) => { 
                
                commit('setCheckoutLineItems', { products: axios_response.body.products, details: axios_response.body.details });
                commit('setCheckoutData', axios_response.body.order);
                commit('setCoupon', axios_response.body.coupon);

            }).catch( (axios_error) => {
                
                commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
            })
        },
        async getAdvancePreorder({commit}, payload){
            
            await this.$hefesto.$post(`/carts/advance-preorder`, payload).then( (axios_response) => { 
                
                commit('setCheckoutLineItems', { products: axios_response.body.products, details: axios_response.body.details });
                commit('setCheckoutData', axios_response.body.order);
                commit('setCoupon', axios_response.body.coupon);
            }).catch( (axios_error) => {
                
                commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
            });
        },
        async getCompletePreorder({commit}, payload){
            
            await this.$hefesto.$post(`/carts/complete-preorder?customer=${ payload.customer }`, payload).then( (axios_response) => { 
                
                commit('setCheckoutLineItems', { products: axios_response.body.products, details: axios_response.body.details });
                commit('setCheckoutData', axios_response.body.order);
                commit('setCoupon', axios_response.body.coupon);
            }).catch( (axios_error) => {
                
                commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
            });
        },
        async getConvertPreorder({commit}, payload){
            
            await this.$hefesto.$post(`/carts/convert-preorder`, payload).then( (axios_response) => { 
                try {
                    
                    const { data }  = axios_response;
                    let data_user 	= {
                        id				: data.user.id,
                        name			: data.user.name,
                        email			: data.user.email,
                        profile_image	: data.user.profile_image != null ? `${ data.user.profile_image }?v=${ randomNumber() }` : null
                    };
                    this.$cookies.set('token-app'	, data.user.token, { path: '/', maxAge: 60 * 60 * 24 * 7 });
                    this.$cookies.set('user-store'	, JSON.stringify( data_user ), { path: '/', maxAge: 60 * 60 * 24 * 7 });
                    
                    commit('setCompletePreorder'        , { 
                        preorder: { 
                            id: payload.id, 
                            name: data.preorder.name 
                        }, 
                        valid: true, 
                        products: data.products, 
                        details: data.details,
                        coupon: null, 
                        user: data.user
                    });
                } catch (error) {
                    console.log( 1, error );
                }
                
            }).catch( (axios_error) => {
                
                commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
            });
        },
        async downloadCartFile({commit}, payload){
            
            await this.$hefesto.$get(`/carts/download-cart-file?new_file=${ payload.new_file }&origin=${ payload.origin }&customer=${ payload.customer }`).then( (axios_response) => { 
                
                commit('setDownloadCartFile', "");
                commit('setDownloadCartFile', axios_response.body.url);
            }).catch( (axios_error) => {
                
                commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
            });
        },
        async deleteCartFile({commit}, payload){
            
            await this.$hefesto.$post(`/carts/delete-cart-file`,{ url_file: payload.url_file }).then( (axios_response) => { 
                
                commit('setDownloadCartFile', "");
            }).catch( (axios_error) => {
                
                commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
            })
        },
        async uploadCartFile({commit, state}, payload){
            
            let result = validUploadFile( payload.file, payload.input_name, payload.max_size, payload.old_file_path, payload.extra_data );
            
            if( result.success ){
                
                await this.$hefesto.$post('/carts/charge-products', result.data ).then( (axios_response) => { 
                    
                    if(state.cart.details.total === axios_response.body.cart.details.total) {
                        
                        commit('setNotification', { content: { type: 'request' }, type: 'error', data: { title: 'Error: Charge Product Cart', message: 'The products do not have the conditions to be added to the cart', app_version: this.$cookies.get('app-version') } });
                    } 
                    else {
                        
                        commit('setNotification', { content: { type: 'request' }, type: 'info', data: { title: 'Info: Charge Product Cart', message: `In your cart there are now ${ axios_response.body.cart.details.count } products`, app_version: this.$cookies.get('app-version') } });
                    }
                    commit('setCoupon', axios_response.body.coupon);
                    commit('setCart', axios_response.body.cart);
                }).catch( (axios_error) => {
                    
                    commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
                });
            }
            else{
                
                commit('setNotification', { content: { type: 'request' }, type: 'error', data: { title: result.title, message: result.message, app_version: this.$cookies.get('app-version') } });
            }
        },
        // =================================================================
        //  COUPON ACTIONS
        // =================================================================
        async addCouponCart({commit}, payload){
            
            await this.$hefesto.$put('/carts/add-coupon-code', {coupon: payload.coupon_id}).then( (axios_response) => { 
                
                commit('setCoupon', axios_response.body.coupon);
                commit('setCart', axios_response.body.cart);
            }).catch( (axios_error) => {
                
                commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
            });
        },
        async getCoupon({ commit }, payload) {
            
            await this.$hefesto.$post(`/app-agent/coupon/applicable`, payload.data).then( (axios_response) => { 
                
                const { body, title, message } = axios_response;
                if( !body.coupon ){
                    
                    commit('setCoupon', { name: null });
                    commit('setNotification', { content: { type: 'request' }, type: 'error', data: { title: "Failed Coupon Appli", message: "Coupon not exist", app_version: this.$cookies.get('app-version') } });
                }
                else{
                    commit('setCoupon', body.coupon);
                    commit('setNotification', { content: { type: 'request' }, type: 'success', data: { title: title, message: message } });
                }
            }).catch( (axios_error) => {
                
                commit('setCoupon', { name: null });
                commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
            })
        },
        // =================================================================
        //  COLLECTION ACTIONS
        // =================================================================
        async updateFilterCollection({ commit }, payload){
            
            commit('setFiltersCollection', payload );
        },
        async resetCollection({ commit }, payload){
            
            commit('setCollection', null );
        },
        async getCollection({ commit }, payload) {
            
            await this.$hefesto.$post(`/collections/${ payload.handle_collection }`, payload).then( (axios_response) => { 
                
                if( axios_response.success ){
                    
                    commit('setCollection', axios_response.body);
                }
                else{
                    
                    commit('setCollection', axios_response.body);
                    commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_response });
                }
            }).catch( (axios_error) => {
                
                commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
            })
        },
        async searchProduct({commit}, payload) {
            
            commit('setSearchProduct', { search_text: payload.search, list: [] });
            
            let request_body = {
                search: payload.search,
                query: null,
                fields: { 
                    variants: 0, 
                    additional_content: 0, 
                    published_at: 0, 
                    updated_at: 0, 
                    deleted_at: 0, 
                    deleted: 0, 
                    status: 0, 
                    status_created: 0 
                },
                options: {
                    paginated: {
                        page: 1,
                        per_page: payload.per_page,
                    },
                    sort: { default_sort: 1 },
                    populate: null,
                    lean: true
                }
            };
            await this.$hefesto.$post('/products/quick-search', request_body).then( (axios_response) => { 
                
                commit('setSearchProduct', { search_text: payload.search, list: axios_response.body, per_page: payload.per_page } );
            }).catch( (axios_error) => {
                
                commit('setSearchProduct', { search_text: payload.search, list: [], per_page: payload.per_page });
            });
        },
        async getCollectionHome({commit}, payload) {
            
            let request_body ={
                query: null,
                fields: null,
                options: {
                    paginated: {
                        page: 1,
                        per_page: 4,
                    },
                    sort: { default_sort: 1 },
                    populate: null,
                    lean: true
                },
                get_filters: false
            };
            await this.$hefesto.$post(`/collections/best-sellers`, request_body).then( (axios_response) => {
                
                commit('setCollectionHome', axios_response.body.paginated_products.documents);
            }).catch( (axios_error) => {
                
                commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
            });
        },
        async getListAddBestSellers({commit}, payload){
            
            await this.$hefesto.$post(`/carts/list-add-best-sellers`, payload).then( (axios_response) => { 
                
                commit('setListAddBestSellers', axios_response.body);
            }).catch( (axios_error) => {
                commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
            });
        },
        // =================================================================
        //  PRODUCT ACTIONS
        // =================================================================
        async getProduct({ commit }, payload) {
            
            let request_body = {
                query               : null,
                fields              : null,
                handle_collection   : payload.handle_collection
            };
            await this.$hefesto.$post( `/products/${ payload.handle }/details`, request_body ).then( (axios_response) => { 
                
                commit('setProduct', axios_response.body);
            }).catch( (axios_error) => {
                
                commit('setProduct', "not-found");
                commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
            });
        },
        async getProductViewed({commit}, payload) {
            
            await this.$hefesto.$post(`/products/recently-viewed`, payload).then( (axios_response) => {
                
                commit('setProductsRecentlyViewed', axios_response.body);
            }).catch( (axios_error) => {
                
                commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
            });
        },
        async getProductVariants({commit}, payload) {
            
            await this.$hefesto.$post(`/products/${ payload.handle }/variants`).then( (axios_response) => { 
                
                commit('setVariants', axios_response.body);
            }).catch( (axios_error) => {
                
                commit('setVariants', []);
            });
        },
        // =================================================================
        //  PRICE CATALOG ACTIONS
        // =================================================================
        async getPriceCatalog({commit}, payload) {
            
            await this.$hefesto.$get(`/products/price-catalog`).then( (axios_response) => { 
                
                commit('setPriceCatalog', axios_response.body);
            }).catch( (axios_error) => {
                
                commit('setPriceCatalog', [])
            })
        },
        async downloadPriceCatalog({commit}, payload) {
            
            await this.$hefesto.$get(`/products/download-price-catalog-file`).then( (axios_response) => { 
                
                commit('setDownloadPriceCatalog', "");
                commit('setDownloadPriceCatalog', axios_response.body.url);
                commit('setNotification', { content: { type: 'request' }, type: 'success', data: axios_response.body });
            }).catch( (axios_error) => {
                
                commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
            })
        },
        async deletePriceCatalog({commit}, payload) {
            
            await this.$hefesto.$get(`/products/delete-price-catalog-file`).then( (axios_response) => { 
                
                commit('setDownloadPriceCatalog', "");
            }).catch( (axios_error) => {
                
                commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
            })
        },
    }
}
